<!--
 * @Descripttion: 
 * @version: 
 * @Author: sueRimn
 * @Date: 2022-03-10 09:12:28
 * @LastEditors: sueRimn
 * @LastEditTime: 2022-03-14 09:13:32
-->
<template>
    <div class="about">
        <Header></Header>
        <div class="about-content">
            <div class="content-top">
                <span>{{item.msg}}</span>
                <span>COMPANY INTRODUCTION</span>
            </div>
            <div class="content-img">
                <img  :src="BaseUrl+item.data.image" alt="">
            </div>
            <div class="content-txt">
               <span v-html="item.data.content"></span>
            </div>
            <div class="content-item" >
                 <img :src="BaseUrl+img" v-for="img in item.data.imglist" :key="img.index">
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '../../../components/mobile/Header.vue'
import Footer from '../../../components/mobile/Footer.vue'
export default {
    name:'About',
    data(){
        return{
             item: "",
        }

    },
  created() {
    this.getData();
  },
   methods:{
       getData() {
      this.$http({
        url: "/about/index",
      }).then((res) => {
        console.log(res, "res");
        this.item = res;
      });
    },
   },
    components:{
        Header,
        Footer
    }
}
</script>

<style lang='less' scoped>
.about{
    .about-content{
         width: 690px;
        margin: 40px auto;
        .content-top{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 59px;
            color: #333333;
            >span:nth-child(1){
                font-size: 28px;
            }
            >span:nth-child(2){
                font-size: 10px;
                transform: scale(0.633333)
            }
        }
        .content-img{
            width: 567px;
            height: 375px;
            margin: 0 auto 38px;
            >img{
                width: 567px;
                height: 375px;
            }
        }
        .content-txt{
            margin-bottom: 64px;
            font-size: 28px;
            color: #666666;
        }
        .content-item{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 45px;
            >img{
                width: 330px;
                height: 260px;
                margin:0 8px 10px 5px;
            }
        }
    }
}
</style>